export const SpecializationData = [
  {
    specialization: "Pediatrics",
  },
  {
    specialization: "Surgery",
  },
  {
    specialization: "Internal Medicine",
  },
  {
    specialization: "Obstetrics and Gynecology",
  },
  {
    specialization: "Psychiatry",
  },
];

export const CaseData = [
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "PENDING",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "PENDING",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "PENDING",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "PENDING",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "PENDING",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "DONE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[0].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-06-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-07-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-08-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[1].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[2].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[3].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-09-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-10-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-11-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
  {
    id: "6433-643524",
    specialization: SpecializationData[4].specialization,
    date: "2022-12-30T02:11:00.000+00:00",
    followup: 2,
    patientGender: "MALE",
    patient: "Jose Marie Chan",
    patientCivilStatus: "TAKEN",
    status: "ACTIVE",
  },
];
